<template>
  <div>
    <div class="payments">
      <div class="inner">
        <div class="title">Interested in Learning More?</div>
        <div class="content">Tens of thousands of global businesses depend on our robust orchestration platform, diverse ecosystem of services, and unmatched market perspective to optimize digital growth.</div>
      </div>
    </div>
    <div class="options">
      <div class="inner">
        <div class="content-list">
          <template v-for="(item, index) in options">
            <div :key="index" class="item">
              <img :src="item.icon" />
              <div class="title">{{item.title}}</div>
              <div class="content">{{item.content}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      options: [
        {
          icon: require('../../assets/images/company_about/icon1.png'),
          title: 'Payments Enablement',
          content: 'PCI Level 1 vault and tokenization, plus the world’s deepest ecosystem of hundreds payment services.'
        },
        {
          icon: require('../../assets/images/company_about/icon2.png'),
          title: 'Payments Optimization',
          content: 'Smart routing and optimization technology built on the expertise of over a billion transactions per year.'
        },
        {
          icon: require('../../assets/images/company_about/icon3.png'),
          title: 'Payments Orchestration',
          content: 'Get to market faster globally with higher authorization rates, more flexibility, and no vendor lock-in.'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';

.payments {
  padding: 132px 0 256px;
  background: url(../../assets/images/company_about/bg1.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: Helvetica-Bold;
      font-size: 52px;
      color: $white;
      letter-spacing: 0;
      line-height: 74px;
    }
    .content {
      font-family: HelveticaNeue;
      font-size: 18px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 36px;
      width: 1000px;
      max-width: 100%;
      margin: 16px auto 0;
    }
  }
}
.options {
  background: $nav-hover-bg-color;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .content-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      box-shadow: 0 0 30px 0 rgba(0,0,0,0.10);
      border-radius: 18px;
      background: $white;
      position: relative;
      top: -120px;
    }
    .item {
      width: 400px;
      max-width: 100%;
      padding: 32px;
      box-sizing: border-box;
      text-align: left;
      img {
        width: 60px;
      }
      .title {
        font-family: HelveticaNeue-Bold;
        font-size: 26px;
        color: $primary-color;
        letter-spacing: 0;
        text-align: left;
        margin-top: 25px;
        margin-bottom: 25px;
      }
      .content {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: $primary-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 32px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .payments, .options {
    .inner {
      padding: 0 15px;
    }
  }
}
</style>
